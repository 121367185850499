import React from 'react';
import Box from '@material-ui/core/Box';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { Transition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';

const FadeInUp = ({ children, width, threshold, timeout = 500 }) => {
    const defaultThreshold = isWidthUp('md', width) ? 0.2 : 0.05;
    const { ref, inView } = useInView({
        threshold: typeof threshold === 'number' ? threshold : defaultThreshold,
        triggerOnce: true,
    });
    
    const slideTransitionStyles = {
        entering: { opacity: '1', transform: 'none' },
        entered:  { opacity: '1', transform: 'none' },
        exiting:  { opacity: '0', transform: 'translateY(50px)' },
        exited:  { opacity: '0', transform: 'translateY(50px)' },
    }

    return (
        <Box ref={ref}>
                <Transition in={inView} timeout={timeout}>
                    {
                        state => (
                            <Box style={{
                                transition: `transform ${timeout}ms cubic-bezier(0, 0, 0.2, 1) 0ms, opacity ${timeout}ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
                                ...slideTransitionStyles[state],
                            }}>
                                {inView &&
                                    children
                                }
                            </Box>
                        )
                    }
                </Transition>
        </Box>
    );
};

export default withWidth()(FadeInUp);
