import React from 'react';
import Fade from '@material-ui/core/Fade';

import { useInView } from 'react-intersection-observer';

const FadeIn = ({ children, timeout = 450 }) => {
    const { ref, inView } = useInView({
        threshold: 0.25,
        triggerOnce: true,
    });

    return (
        <Fade ref={ref} in={inView} timeout={timeout}>
            {children}
        </Fade>
    );
};

export default FadeIn;
