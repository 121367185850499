import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import ContactContext from '../../context/Contact';

import { FormFieldNames, FORM_NAME } from '../../constants/forms/contact-form';

const ContactForm = ({ shouldFocusInputOnMount }) => {
    const {
        botVerification,
        email,
        errors,
        isSubmittingForm,
        message,
        name,
        phone,
        company,
        updateContactState,
    } = React.useContext(ContactContext);
    const nameFieldRef = React.useRef();

    React.useEffect(() => {
        if (shouldFocusInputOnMount && nameFieldRef.current) {
            // The ref is to the div that material-ui wraps the input in;
            // therefore, we need to find the input element within
            nameFieldRef.current.getElementsByTagName('input')[0].focus();
        }
    }, [shouldFocusInputOnMount]);

    const updateField = fieldName => {
        return (event) => {
            updateContactState({
                [fieldName]: event.target.value,
            });
        };
    }

    return (
        <form
            autoComplete="off"
            data-netlify="true"
            method="post"
            name={FORM_NAME}
            noValidate
            onSubmit={e => e.preventDefault()}
        >
            { /** Necessary for Netlify to pick up a form built with Gatsby */ }
            <input type="hidden" name="form-name" value={FORM_NAME} />
            <Box pb={2}>
                <TextField
                    disabled={isSubmittingForm}
                    error={!!errors.company}
                    fullWidth
                    helperText={errors.company}
                    id={FormFieldNames.COMPANY}
                    name={FormFieldNames.COMPANY}
                    label="Company Name"
                    margin="dense"
                    onInput={updateField(FormFieldNames.COMPANY)}
                    ref={nameFieldRef}
                    required
                    value={company}
                />
            </Box>
            <Box pb={2}>
                <TextField
                    disabled={isSubmittingForm}
                    error={!!errors.name}
                    fullWidth
                    helperText={errors.name}
                    id={FormFieldNames.NAME}
                    name={FormFieldNames.NAME}
                    label="Your Name"
                    margin="dense"
                    onInput={updateField(FormFieldNames.NAME)}
                    required
                    value={name}
                />
            </Box>
            <Box pb={2}>
                <TextField
                    disabled={isSubmittingForm}
                    error={!!errors.email}
                    fullWidth
                    helperText={errors.email}
                    id={FormFieldNames.EMAIL}
                    name={FormFieldNames.EMAIL}
                    label="Email Address"
                    margin="dense"
                    onInput={updateField(FormFieldNames.EMAIL)}
                    required
                    type="email"
                    value={email}
                />
            </Box>
            <Box pb={2}>
                <TextField
                    disabled={isSubmittingForm}
                    error={!!errors.phone}
                    fullWidth
                    helperText={errors.phone}
                    id={FormFieldNames.PHONE}
                    name={FormFieldNames.PHONE}
                    label="Phone Number"
                    margin="dense"
                    onInput={updateField(FormFieldNames.PHONE)}
                    value={phone}
                />
            </Box>
            <Box pb={2}>
                <TextField
                    disabled={isSubmittingForm}
                    error={!!errors.message}
                    fullWidth
                    helperText={errors.message}
                    id={FormFieldNames.MESSAGE}
                    name={FormFieldNames.MESSAGE}
                    label="Message"
                    margin="dense"
                    onInput={updateField(FormFieldNames.MESSAGE)}
                    multiline
                    rows={4}
                    value={message}
                />
            </Box>
            <Box display="none">
                <TextField
                    disabled={isSubmittingForm}
                    id={FormFieldNames.CURRENT_PAGE}
                    name={FormFieldNames.CURRENT_PAGE}
                    label="This field is empty"
                    onInput={updateField(FormFieldNames.CURRENT_PAGE)}
                    value=""
                />
            </Box>
            <Box display="none">
                <TextField
                    disabled={isSubmittingForm}
                    id={FormFieldNames.BOT}
                    name={FormFieldNames.BOT}
                    label="This field is for verification, please leave empty"
                    onInput={updateField(FormFieldNames.BOT)}
                    value={botVerification}
                />
            </Box>
        </form> 
    );
}

ContactForm.defaultProps = {
    shouldFocusInputOnMount: false,
};

export default ContactForm;
